export const EMAIL_STATUS_BLOCKED = 'blocked';
export const EMAIL_STATUS_CLICKED = 'clicked';
export const EMAIL_STATUS_CONVERTED = 'converted';
export const EMAIL_STATUS_OPENED = 'opened';
export const EMAIL_STATUS_OPENED_MULTIPLE_TIMES = 'opened-multiple-times';
export const EMAIL_STATUS_PROCESSING = 'processing';
export const EMAIL_STATUS_RECEIVED = 'received';
export const EMAIL_STATUS_NONE = '';

export const EMAIL_STATUS_VALUES = [
  EMAIL_STATUS_BLOCKED,
  EMAIL_STATUS_CLICKED,
  EMAIL_STATUS_CONVERTED,
  EMAIL_STATUS_OPENED,
  EMAIL_STATUS_OPENED_MULTIPLE_TIMES,
  EMAIL_STATUS_PROCESSING,
  EMAIL_STATUS_RECEIVED,
  EMAIL_STATUS_NONE,
] as const;

export type EmailStatus = (typeof EMAIL_STATUS_VALUES)[number];
